import axios from 'axios';

const Api = async (url,method,payload) => {

   // console.log(method,payload);

    if(method == "POST"){

        const call =  await axios.post(url,payload);
        
        return call;
       

    }
    else if(method == "DELETE"){


        const call =  await axios.delete(url,payload);
        return call;

    }
    else{
       
        const call =  await axios.get(url,payload);
        return call
    }
}

export default Api;