import React, { useEffect, useState,useCallback}  from 'react'
//import './home.css';
import Api from '../Service/network';
import { FormControl, InputLabel,Select,MenuItem, TextField, Checkbox} from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
import { usePlatform, usePlatformRes, useCampaignTypeRes, 
      useCampaignOwner,
      usePerformanceManager,
      useCampaignBackup,
      useTeamLeader,
      useEditCampaignDetails
} from '../Zustand/store';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { isObject } from '@okta/okta-auth-js';


const Step4 = (props) => {

/////////////////Zustand///////////////////////////////
const campaignOwner = useCampaignOwner(state  => state.campaignOwner)
const setCampaignOwner = useCampaignOwner(state  => state.setCampaignOwner)


const performanceManager = usePerformanceManager(state  => state.performanceManager)
const setPerformanceManager = usePerformanceManager(state  => state.setPerformanceManager)

const campaignBackup = useCampaignBackup(state  => state.campaignBackup)
const setCampaignBackup = useCampaignBackup(state  => state.setCampaignBackup)

const teamLeader = useTeamLeader(state  => state.teamLeader)
const setTeamLeader = useTeamLeader(state  => state.setTeamLeader)

const campaignEditDetails = useEditCampaignDetails(state  => state.campaignDetails)

const [users,setUsers] = useState([]);

const [campaignOwnerVal, setCampaignOwnerVal] = useState(false);
const [campaignBackupVal, setCampaignBackupVal] = useState(false);
const [performanceManagerVal, setPerformanceManagerVal] = useState(false);
const [teamLeaderVal, setTeamLeaderVal] = useState(false);

useEffect(()=>{
    window.scrollTo({  top: 0,left: 0, behavior: "smooth" });
  },[])
  

useEffect(() => {


    const endpoint = "https://mediafy-backend-dot-groupm-dev-dev.nw.r.appspot.com/v1/user/";

    Api(endpoint,"GET")
    .then(result => {

     
      

        setUsers(result.data);


     }).catch((err)=>console.log(err))
    



},[])




///////////////////////////////////////////////////////////////////////////////////////////


const validate = ()  => {
    


    if(campaignOwner == ""){
           
        setCampaignOwnerVal(true);
    }
    else if(performanceManager == ""){
        setPerformanceManagerVal(true);
    }
    else if(campaignBackup == ""){
        setCampaignBackupVal(true);
    }
    else if(teamLeader == ""){
        setTeamLeaderVal(true);
    }
    else{
            
            // alert("from step 3")

            return true;
    }

    return false;

}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


props.step4Ref.current = {
    validate: validate
};

  return (
   <div className="card-body" >

<form>
    <div className="form first">
        <div className="details personal" >
            

           

            <div className="fields_second" style={{margin:'10px', padding:'10px'}}>
                    
                

                    <div className="input-field" fullWidth>

                                <Autocomplete
                                    
                                    value={campaignOwner[0]}
                                    disablePortal
                                    id="combo-box-demo"
                                    options={users}
                                    getOptionLabel={(option) => option.FullName ? option.FullName: campaignOwner[0]}

                                    onChange={(index, value) =>
                                        { 

                                           
                                            setCampaignOwner([value.FullName,value.LoginName], setCampaignOwnerVal(false))
                                        
                                        }}
                                
                                    renderInput={(users) => <TextField {...users} label={campaignOwnerVal ? <span style={{color:'red'}}>Please Choose Campaign Owner</span> : "Campaign Owner"}   variant="outlined" />}
                                  
                                    error={campaignBackupVal}
                            />


                    </div>



                    <div className="input-field" >
                    <Autocomplete
                                    
                                    value={performanceManager[0]}
                                    disablePortal
                                    id="combo-box-demo"
                                    options={users}
                                    getOptionLabel={(option) => option.FullName ? option.FullName: performanceManager[0]}

                                    onChange={(index, value) =>
                                        { 
                                            setPerformanceManager([value.FullName,value.LoginName], setPerformanceManagerVal(false))
                                        
                                        }}
                                
                                    renderInput={(users) => <TextField {...users} label={performanceManagerVal ? <span style={{color:'red'}}>Please Choose Performance Manager</span> : "Performance Manager"}  variant="outlined" />}
                                  
                                    
                            />
                    </div>



             </div>

             <div className="fields_second" style={{margin:'10px', padding:'10px'}}>
                    
                  

                    <div className="input-field" fullWidth>

                                <Autocomplete
                                    
                                    value={campaignBackup[0]}
                                    disablePortal
                                    id="combo-box-demo"
                                    options={users}
                                    getOptionLabel={(option) => option.FullName ? option.FullName: campaignBackup[0]}

                                    onChange={(index, value) =>
                                        { 
                                            setCampaignBackup([value.FullName,value.LoginName], setCampaignBackupVal(false))
                                        
                                        }}
                                
                                    renderInput={(users) => <TextField {...users} label={campaignBackupVal ? <span style={{color:'red'}}>Please Choose Campaign Backup</span> : "Campaign Backup"}  variant="outlined" />}
                                  
                                    
                            />


                    </div>



                    <div className="input-field" >
                    <Autocomplete
                                    
                                    value={teamLeader[0]}
                                    disablePortal
                                    id="combo-box-demo"
                                    options={users}
                                    getOptionLabel={(option) => option.FullName ? option.FullName: teamLeader[0]}

                                    onChange={(index, value) =>
                                        { 
                                            setTeamLeader([value.FullName,value.LoginName], setTeamLeaderVal(false))
                                        
                                        }}
                                
                                    renderInput={(users) => <TextField {...users} label={teamLeaderVal ? <span style={{color:'red'}}>Please Choose Team Leader</span> : "Team Leader"}  variant="outlined" />}
                                  
                                    
                            />
                    </div>



             </div>






               </div>

        

        </div>

      
        
        </form>
</div>
  )
}

export default Step4