import React,{useState,useEffect} from 'react';
//import { useNavigate } from "react-router-dom";
import Api from '../Service/network';
import AddIcon from '@mui/icons-material/Add';
import { LoadingButton, Button,Dialog, FormControl,TextField,Box, DialogTitle} from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
import CloseIcon from '@mui/icons-material/Close';
import LinearProgress from '@mui/material/LinearProgress';
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import AppBar from '../Layout/appBar';
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import MUIDataTable from "mui-datatables";
import Header from '../Layout/header';
import Footer from '../Layout/footer';
import { isObject } from '@okta/okta-auth-js';



const MasterCampaign = () => {
  const [responsive, setResponsive] = useState("vertical");
  const [tableBodyHeight, setTableBodyHeight] = useState("600px");
  const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("700px");
  const [searchBtn, setSearchBtn] = useState(true);
  const [downloadBtn, setDownloadBtn] = useState(true);
  const [printBtn, setPrintBtn] = useState(true);
  const [viewColumnBtn, setViewColumnBtn] = useState(false);
  const [filterBtn, setFilterBtn] = useState(false);



const [clientLoading,setClientLoading] = useState(false);
const [client,setClient] = useState([]); 
const [clientRes,setClientRes] = useState([]); 

const[listMasterCampaign,setListMasterCampaign] = useState([]);
const[masterCampaign, setMasterCampaign ] = useState();

const[totalBudget, setTotalBudget ] = useState();
const [dataLoading,setDataLoading] = useState(true);



useEffect(()=>{

    
    const endpoint = "https://mediafy-backend-dot-groupm-dev-dev.nw.r.appspot.com/v1/getAccounts";
 
    Api(endpoint,"POST", { platform: "Mediafy"})
    .then(result => {
      
    
      
      setClientRes(result.data.res);
      setClientLoading(false);
      
     }).catch((err)=>console.log(err))
     

},[])



useEffect(()=>{

    
    const endpoint = "https://mediafy-backend-dot-groupm-dev-dev.nw.r.appspot.com/v1/mastercampaign/";
  //  setClientLoading(true);
    Api(endpoint,"GET")
    .then(result => {
     
    //   console.log(result,"Tes")
        setListMasterCampaign(result.data);
        setDataLoading(false);
      
     }).catch((err)=>console.log(err))
     

},[masterCampaign])




    const [open, setOpen] = React.useState(false);


  //  let navigate = useNavigate();


    const navigateAddMasterCampaign = () => {
      //  navigate('/addMasterCampaign');
    }


    const handleClickOpen = () => {
        setOpen(true);
      };
     
      const handleClose = () => {
        setOpen(false);
      //  window.location.reload();
     
      };
    




    const addMasterCampaign = () => {
      setEditMode(false);
      setMasterCampaign('');
      setTotalBudget('');
      setClient('');
        setOpen(true);

    }

    const deleteMasterCampaign = (id) => {
       

        let isExecuted = window.confirm("Are you sure you want to delete this Master Campaign?");


        if(isExecuted){

        const endpoint = "https://mediafy-backend-dot-groupm-dev-dev.nw.r.appspot.com/v1/mastercampaign/delete/"+id;
       // setClientLoading(true);
         Api(endpoint,"DELETE")
        .then(result => {
         
          
            setMasterCampaign([]);
          
         }).catch((err)=>console.log(err))
        }
         
    }


    const submitMasterCampaign = () => {
        
        
         
   
            setClientLoading(true)

            var myHeaders = new Headers();

            myHeaders.append("Content-Type", "application/json");


            var raw= JSON.stringify({

            "master_campaign_name":masterCampaign,

            "total_budget":totalBudget,

            "client":client

            });




            var requestOptions={

                method:'POST',

                headers:myHeaders,

                body:raw,

                redirect:'follow'

            };




            fetch("https://mediafy-backend-dot-groupm-dev-dev.nw.r.appspot.com/v1/extractMasterCampaign", requestOptions)

            .then(response=>{
                setClientLoading(false);
                setOpen(false);
                setMasterCampaign();
                setTotalBudget();
                
            })

            .catch(error=>console.log('error',error));




    }


    const [editMode, setEditMode] = useState(false);
    const [mid,setMid] = useState();

    const editMasterCampaign = (id) => {

    const endpoint = "https://mediafy-backend-dot-groupm-dev-dev.nw.r.appspot.com/v1/mastercampaign/"+id;

    Api(endpoint,"GET")
    .then(result => {


     console.log(result,"test");

     setMid(id);
     setMasterCampaign(result.data.master_campaign_name);
     setTotalBudget(result.data.total_budget);
     setClient(result.data.client)

       
     }).catch((err)=>console.log(err))



     setEditMode(true);
      setOpen(true);
    


    }

    const submitEditMasterCampaign = () =>{

      

        var myHeaders = new Headers();

        myHeaders.append("Content-Type", "application/json");


        var raw= JSON.stringify({

        "master_campaign_name":masterCampaign,

        "total_budget":totalBudget,

        "client":client

        });




        var requestOptions={

            method:'PUT',

            headers:myHeaders,

            body:raw,

            redirect:'follow'

        };




        fetch("https://mediafy-backend-dot-groupm-dev-dev.nw.r.appspot.com/v1/mastercampaign/update/"+mid, requestOptions)

        .then(response=>{
           
            setOpen(false);
            setMasterCampaign();
            setTotalBudget();
            
        })

        .catch(error=>console.log('error',error));







    }



    const columns = [
      { name:"Advertiser", options: {
          filter:true,
         
          
          }, 
      },
  
      { name:"Master Campaign", options: {
         
          }, 
      },
  
      { name:"Total Budget", options: {
       
          }, 
      },
      {
        name:"id", options: {display: false, viewColumns: false, filter: false}
      },
      {
        name: "",
        options: {
          setCellProps: () => ({ style: { maxWidth: "400px",  }}), 
       
          filter: false,
          viewColumns:false,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex) => {
            return (
              <a onClick={(e) => {
                e.stopPropagation()
                editMasterCampaign(data[dataIndex][3]);
              // console.log(data[dataIndex][3],"afds");
              }}>
              <span className="las la-edit" style={{cursor:'pointer', marginLeft:5, fontSize:20}} ></span> 
              </a>
            );
          }
        }
      },
   /*   {
        name: "",
        options: {
          filter: false,
          viewColumns:false,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex) => {
            return (
              <a onClick={() => {
               
              deleteMasterCampaign(data[dataIndex][3]);
              // console.log(data[dataIndex][3],"afds");
              }}>
              <span className="las la-trash" style={{cursor:'pointer', marginLeft:5, fontSize:20}} ></span> 
              </a>
            );
          }
        }
      }*/
  
     
      ];
  
       
    const options = {
      search: searchBtn,
      download: downloadBtn,
      print: printBtn,
      viewColumns: viewColumnBtn,
      filter: filterBtn,
      filterType: "dropdown",
      responsive,
      tableBodyHeight,
      tableBodyMaxHeight,
      selectableRows: 'none',
      responsive: "standard",
      
   
    
       
    };

    const data = listMasterCampaign.map((a,b)=>{return(
      [
      a.client,
      a.master_campaign_name,
      "€ "+a.total_budget,
      a._id 
      
      ]
    )})
  



  return (
    
<>
<Header/>

      
<div className="main-content">
  
<AppBar/>


        <main>
           
        <Button variant="contained" startIcon={<AddIcon />} onClick={addMasterCampaign} style={{marginBottom:'10px',backgroundColor:'#4169e1', color:'white'}}  >
        Add Master Campaign
         </Button>
        <div> 


            <div className="projects">

            <div className="card">
                  
            {dataLoading ? 
              <LinearProgress style={{marginBottom:5}} />
            :''}

            <ThemeProvider theme={createTheme()}>
            
              <MUIDataTable
              // title={"Campaign Information"}   
                data={data}
                columns={columns}
                options={options}
              
              
              />
          </ThemeProvider>

                    
                  {/**
                    <table width="100%">
                        <thead>
                            <tr>
                                <td width="30%">Advertiser</td>
                                <td width="40%">Master Campaign</td>
                                <td width="20%">Total Budget</td>
                               
                               <td></td>
                              

                            </tr>
                        </thead>
                        <tbody>
              
                           
                                {listMasterCampaign.map((key,index)=>{
                                    return(

                                    <tr style={{border:'1px solid lightgray'}}>
                                    <td style={{width:'10px'}}>{key.client}

                                    </td>
                                    <td >{key.master_campaign_name}</td>
                                    <td  >{key.total_budget}</td>
                                    <td>

                                        <DeleteForeverIcon  style={{color:'red',cursor:'hand'}} onClick={() => deleteMasterCampaign(key._id)} />
                                    </td>
                                   
                                </tr>

                                )})}
                              
                   
                        </tbody>
                    </table>
                     */}
           
            </div>
                
            </div>
            



            </div>
       

        </main>




        <Dialog
            open={open}
           // onClose={handleClose}
            fullWidth={true}
            maxWidth='md'
            
        >
      
        <div style={{display:'flex', justifyContent:'end'}}>
        <CloseIcon  onClick={handleClose} style={{padding:5, }}/>
        </div>
            <div style={{marginTop:"1%",padding:'4%',}}>

                
                <div style={{display:'flex', flexDirection:'column', justifyContent:'center'}}>

             
                    
                <FormControl variant="outlined" fullwidth   >
                                <TextField fullWidth variant="outlined"
                                    
                                        id="demo-simple-select"
                                        value={masterCampaign}
                                        onChange={(e) => {setMasterCampaign(e.target.value);}}
                                        label="Master Campaign Name" 
                                        //error={masterCampaignVal}       
                                        
                                        disabled = {editMode ? true : false} 
                                     
                                />
                </FormControl>
                 


                <FormControl variant="outlined" fullwidth style={{marginTop:'20px'}}   >
                                <TextField fullWidth variant="outlined"
                                    
                                        id="demo-simple-select"
                                        value={totalBudget}
                                        onChange={(e) => {setTotalBudget(e.target.value); }}
                                        label= "Add Total Budget" 
                                        
                                    //    error={masterCampaignVal}
                                    
                                    
                                />
                </FormControl>



                <FormControl variant="outlined" fullwidth  style={{marginTop:'20px'}} >
                <Autocomplete
                          
                          value={client}
                          disablePortal
                          id="combo-box-demo"
                          options={clientRes}
                          getOptionLabel={(option) => option.clean_advertiser ? option.clean_advertiser: client}
                       
                          renderInput={(client) => <TextField {...client} label="Choose Advertiser"  variant="outlined" />}
                          onChange={(index, value) =>
                          { 
                            //  getCampaign(index, value.account_id,value.account_name);
                             // setClientVal(false);
                          
                             setClient(value.clean_advertiser);
                          
                          }}

                          disabled = {editMode ? true : false} 
                          
                  />

               
                </FormControl>



                
     
                </div>

             
               
                
                

            </div>

          
            <div style={{display:'flex', flexDirection:'column', marginLeft:50, marginBottom:30, width:'90%', textAlign:'center'}}>

                {clientLoading ?
                <LinearProgress style={{marginBottom:5}} />
                :""}
      

                 {editMode ? 
                <Button variant='contained' onClick={submitEditMasterCampaign} style={{backgroundColor:'#4169e1', color:'white'}}>Edit Master Campaign</Button>
                :
                <Button variant='contained' onClick={submitMasterCampaign} style={{backgroundColor:'#4169e1', color:'white'}}>Add Master Campaign</Button>
                 }

            </div>


         
          
            </Dialog>
        
        
   </div>

<Footer/>

</>

  )
}

export default MasterCampaign