import React from 'react';
import { Route, useHistory, Switch } from 'react-router-dom';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security, SecureRoute, LoginCallback,} from '@okta/okta-react';
import Login from './components/Login';
import { Logout } from './components/Login/logout';
import AddCampaign from './components/Campaign/addCampaign';
import MasterCampaign from './components/MasterCampaign';
import Dashboard from './components/Dashboard';
import ListingDetails from './components/Dashboard/listingDetails';

const oktaAuth = new OktaAuth({
  // issuer: 'https://trial-6818659.okta.com/oauth2/default',
   //clientId: '0oa3hr61kwm8js18X697',

   //prod-groupm
   issuer: 'https://wpp.okta.com/oauth2/default', 
   clientId: '0oa5tdgnmu6QmyNYu417',
   
   //dev groupm
 //  issuer: 'https://devh-wpp.okta.com/oauth2/default', 
 //  clientId: '0oa54zlmf4iGjrqCK417',
   redirectUri: window.location.origin + '/login/callback',
   scopes: ['openid', 'profile', 'email'],
   pkce: true,
   disableHttpsCheck: true,

   

});


const App = () => {

  const [authRequiredModalOpen, setAuthRequiredModalOpen] = React.useState(false);

  const history = useHistory();

  const triggerLogin = async () => {
    await oktaAuth.signInWithRedirect();
  };

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  const customAuthHandler = async () => {
    const previousAuthState = oktaAuth.authStateManager.getPreviousAuthState();
    if (!previousAuthState || !previousAuthState.isAuthenticated) {
      // App initialization stage
      await triggerLogin();
    } else {
      // Ask the user to trigger the login process during token autoRenew process
      setAuthRequiredModalOpen(true);
    }
  };

  return (
    <Security
      oktaAuth={oktaAuth}
      onAuthRequired={customAuthHandler}
      restoreOriginalUri={restoreOriginalUri}
    >
     
        <Switch>
          <Route path="/" exact component={Login} />
          <Route path="/login/callback" component={LoginCallback} />
          <SecureRoute path="/dashboard" component={Dashboard} />
          <SecureRoute path="/addCampaign" component={AddCampaign} />
          <SecureRoute path="/masterCampaign" component={MasterCampaign} />
         
        </Switch>
     
    </Security>

    
  );
};



export default App;
