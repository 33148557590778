import React, {useState, useRef} from 'react'
import {usePickDates,useGoogleCampaign, useTotalBudget, useTotalEstimatedCostPerConversion, useTotalEstimatedGoal,usePeriodValidate, useCpaValidate, useCpcValidate, useGoogleBudget,useGoal, usePeriodTotalBudget, useEstimatedTotalGoal, useEditCampaignDetails,
    useCampaignBackup,useCampaignOwner,usePerformanceManager,useTeamLeader, useNotes,useContinousSecondaryKpi, useEstimatedSecondaryKpi, useFrontSecondaryApi, useOptimizationKpi, usePeriods, usePeriodBudget
} from '../Zustand/store';
import { FormControl, InputLabel,Select,MenuItem, TextField} from '@material-ui/core';
import Continuous from '../Modes/continuous';
import Periods from '../Modes/periods';
import { useEffect } from 'react';
import CurrencyFormat from 'react-currency-format';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { isObject } from '@okta/okta-auth-js';


const Step3 = (props) => {

   // const [budget,setBudget] = useState();

    const  totalGoogleBudget = useGoogleBudget(state => state.budget);
    const  setGoogleBudget = useGoogleBudget(state => state.setGoogleBudget);

    const totalBudget = useTotalBudget(state => state.totalBudget);
    const setTotalBudget = useTotalBudget(state => state.setTotalBudget);

    const estimatedGoal = useTotalEstimatedGoal(state => state.estimatedGoal);
    const setEstimatedGoal = useTotalEstimatedGoal(state => state.setEstimatedGoal);

    const estimatedCostPerConversion = useTotalEstimatedCostPerConversion(state => state.estimatedCostPerConversion);
    const setEstimatedCostPerConversion = useTotalEstimatedCostPerConversion(state => state.setEstimatedCostPerConversion);

    const checkMode = usePickDates(state  => state.dates);

    const googleCampaign = useGoogleCampaign(state  => state.google_campaign);

    const goal = useGoal(state  => state.goal);

    const setPeriodValidate = usePeriodValidate(state  => state.setValidate);
    const setCpaValidate = useCpaValidate(state  => state.setValidate);
    const setCpcValidate = useCpcValidate(state  => state.setValidate);


    const [totalBudgetVal, setTotalBudgetVal] = useState(false);
    const [estimatedGoalVal, setEstimatedGoalVal] = useState(false);

    
    const [continuousSecondaryKpiVal, setContinuousSecondaryKpiVal] = useState(false);

    const  estimatedSecondaryKpi = useEstimatedSecondaryKpi(state => state.skpi);
    
    const periodTotalBudget = usePeriodTotalBudget(state  => state.pbudget);

    const estimatedTotalGoal = useEstimatedTotalGoal(state  => state.tgoal);

    const campaignEditDetails = useEditCampaignDetails(state  => state.campaignDetails);

    const continuousSecondaryKpi = useContinousSecondaryKpi(state => state.Kpi);

    const campaignOwner = useCampaignOwner(state  => state.campaignOwner)
    const setCampaignOwner = useCampaignOwner(state  => state.setCampaignOwner)

   


    
    
    const performanceManager = usePerformanceManager(state  => state.performanceManager)
    const setPerformanceManager = usePerformanceManager(state  => state.setPerformanceManager)
    
    const campaignBackup = useCampaignBackup(state  => state.campaignBackup)
    const setCampaignBackup = useCampaignBackup(state  => state.setCampaignBackup)
    
    const teamLeader = useTeamLeader(state  => state.teamLeader)
    const setTeamLeader = useTeamLeader(state  => state.setTeamLeader)

    const setNotes = useNotes(state => state.setNotes)
   
    const frontSecondaryKpi = useFrontSecondaryApi(state => state.Kpi);

    const optimizationKPI = useOptimizationKpi(state => state.Kpi);

    const countperiods = usePeriods(state  => state.periods);

    useEffect(()=>{
        window.scrollTo({  top: 0,left: 0, behavior: "smooth" });
      },[])
      
    

    const googleBudget = () => {

        let budget = 0;
        googleCampaign.map((key,index) => {
            budget += key.budget           
        })

        setGoogleBudget(budget);
      //  setBudget(budget);

    }


    let periods = useRef(null);


    const setUsers = () => {
        if(isObject(campaignEditDetails)){
          
            setNotes(campaignEditDetails.note);
            setCampaignOwner([campaignEditDetails.campaign_owner, campaignEditDetails.campaign_owner])
            setPerformanceManager([campaignEditDetails.performance_manager,campaignEditDetails.performance_manager])
    
            setCampaignBackup([campaignEditDetails.campaign_backup, campaignEditDetails.campaign_backup])
            setTeamLeader([campaignEditDetails.team_leader, campaignEditDetails.team_leader])
        }
    }
    
    

    const validate = ()  => {

        if(isObject(campaignEditDetails)){  setUsers() } ;
      
       if( checkMode === "continuous" )
       {


                if(totalBudget == "" || totalBudget == undefined){
                        
                    setTotalBudgetVal(true);
                }
                else if(estimatedGoal == ""){
                    
                    setEstimatedGoalVal(true);
 
                }
                else if(frontSecondaryKpi.length > 0  && continuousSecondaryKpi == ""){

                    notifyContinuousSKPI();
                    
                }
                else{
                    return true;
                }

       }
       else{




                if(totalBudget == ""){
                
                    setTotalBudgetVal(true);
                }
                else if(estimatedGoal == ""){
                    setEstimatedGoalVal(true);

                //  console.log(totalBudget.replace(/[€,]+/g,"")+" -- "+ periodTotalBudget,"wtf");
                }
                else if(totalBudget.replace(/[€,]+/g,"") != periodTotalBudget)
                {

                    const t = totalBudget.replace(/[€,]+/g,"");

                //  console.log(totalBudget.replace(/[€,]+/g,"")+" -- "+ periodTotalBudget,"wtf");
                    notifyPeriodsBudget();
                }
                else if(estimatedGoal != estimatedTotalGoal)
                {
                    notifyEstimatedTotal();
                }
                else if( frontSecondaryKpi.length > 0  && validateSKPIData() == false ){
                    
                    notifyPeriodsSKPI();

                }
                else{
                        // alert("from step 3")

                        return true;

                        
                }
            }

        return false;
    
     
     }


     function validateSKPIData(){

        var bool ;



       countperiods.map((a,b) => {



        
                if(estimatedSecondaryKpi[b] == "" || estimatedSecondaryKpi[b] == undefined){

                    bool = false;
                }
               

                       

        }) 

      
        return bool;

       
     }


     const notifyContinuousSKPI = () => toast.error("Please Enter Estimated " + frontSecondaryKpi ,
     {
         position: "top-right",
         autoClose: 5000,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
         progress: undefined,
         theme: "light",
     }
     );

     const notifyPeriodsSKPI = () => toast.error("Please Enter Estimated " + frontSecondaryKpi ,
     {
         position: "top-right",
         autoClose: 5000,
         hideProgressBar: false,
         closeOnClick: true,
         pauseOnHover: true,
         draggable: true,
         progress: undefined,
         theme: "light",
     }
     );
     
     

  

const notifyPeriodsBudget = () => toast.error("Total Budget and Periods Total Buget does not match",
{
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
}
);


const notifyEstimatedTotal = () => toast.error("Estimated " + goal + " and Total Estimated "+ goal + " does not match",
{
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
}
);

     const addTotalBudget = (e) => {

       
        setTotalBudgetVal(false);
        setPeriodValidate(true);

        
        setTotalBudget(e);

     }


     const addEstimatedGoal = (e) => {

        setEstimatedGoalVal(false);
        setCpaValidate(true);
        setEstimatedGoal(e);

     }


     const addEstimatedCostPerConversion = (e) => {


        setCpcValidate(true);
        setEstimatedCostPerConversion(e);

     }
     
     
     
     props.step3Ref.current = {
         validate: validate
     };

   

  return (

    <>
   <div className="card-body">

       
   <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        
        />

<form>
    <div className="form first">
        <div className="details personal" >

        {/** depricated for now
        <div className="fields" style={{margin:'10px', padding:'10px', backgroundColor:'aliceblue', justifyContent:'center'}}>
            <span style={{fontSize:'12px', fontWeight:900}}> Platform Budget: </span> &nbsp;&nbsp;{Math.round(totalGoogleBudget)} €
        </div>
         */}  

            <div className="fields_second" style={{margin:'10px', padding:'10px', backgroundColor:'aliceblue'}}>

                <div className="input-field">
                <FormControl variant="outlined"  fullWidth >
                    <CurrencyFormat fullWidth variant="outlined"
                            // value={totalBudget.length > 0 ? (totalBudget.replace(/[€,]+/g,"")).toFixed(2)  : totalBudget }
                             value={totalBudget.length > 0 ? totalBudget : ''}
                             customInput={TextField}
                             decimalSeparator="."
                             thousandSeparator=","
                             prefix="€" 
                             decimalScale={2}
                             // placeholder="Amount"
                            label={totalBudgetVal ? <span style={{color:'red'}}>Please Enter Total Budget</span> : "Total Budget (€)"}
                            error={totalBudgetVal}
                            onChange={(e) => { addTotalBudget(e.target.value);}}
                        
                        
                    />
                  
                    </FormControl>
                    
                </div> 


                <div className="input-field">
                    
                <FormControl variant="outlined"  fullWidth >
                    <TextField fullWidth variant="outlined"
                    
                            type="number"
                            id="demo-simple-select"
                            value={estimatedGoal}
                            onChange={(e) => {addEstimatedGoal(e.target.value);}}
                            
                            label={estimatedGoalVal ? <span style={{color:'red'}}>Please Enter Estimated {goal}</span> : "Estimated" + " " + goal }
                            error={estimatedGoalVal}
                        
                        
                    />
                    </FormControl>
                    
                </div>

              

            </div>

                {checkMode === "continuous" ?  <Continuous  />   : ''   }
           
                {checkMode === "periods" ?  <Periods info />   : ''   }
           
               
        </div>

        </div>

        </form>

        </div>
        </>
  )
}

export default Step3