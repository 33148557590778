import { isObject } from '@okta/okta-auth-js';
import React, { useEffect,useState } from 'react'
import {usePeriods,useOptimizationKpi,useTotalBudget, useTotalEstimatedGoal, useTotalEstimatedCostPerConversion, usePeriodBudget, 
    usePeriodValidate, useCpaValidate,useCpcValidate, useEstimatedGoal, useEstimatedCostPerConversion, usePeriodsInfo, useGoal, useEstimatedSecondaryKpi,  useFrontSecondaryApi, usePeriodsDaysOff,usePeriodsDaysOffInfo,
    usePeriodTotalBudget,useEstimatedTotalGoal, useEditCampaignDetails, useEstimatedPeriodsGoal
} from '../Zustand/store';



const Periods = () => {

const periods = usePeriods(state  => state.periods);
const optimizationKpi = useOptimizationKpi(state  => state.Kpi);
const totalBudget = useTotalBudget(state  => state.totalBudget.length > 0 ? state.totalBudget.replace(/[€,]+/g,""): state.totalBudget);
const totalEstimatedGoal = useTotalEstimatedGoal(state  => state.estimatedGoal);
const totalEstimatedCostPerConversion = useTotalEstimatedCostPerConversion(state  => state.estimatedCostPerConversion);


const periodBudget = usePeriodBudget(state  => state.periodBudget);
const setPeriodBudget = usePeriodBudget(state  => state.setPeriodBudget);


const setPeriodTotalBudget = usePeriodTotalBudget(state  => state.setPeriodTotalBudget);

const setEstimatedTotalGoal = useEstimatedTotalGoal(state  => state.setEstimatedTotalGoal);



const estimatedPeriodsGoal = useEstimatedPeriodsGoal(state  => state.estimatedPeriodsGoal);
const setEstimatedPeriodsGoal = useEstimatedPeriodsGoal(state  => state.setEstimatedPeriodsGoal);


const periodsDaysOff = usePeriodsDaysOff(state => state.periodsDaysOff);


const setPeriodsDaysOffInfo = usePeriodsDaysOffInfo(state => state.setPeriodsDaysOffInfo);

const periodsInfo = usePeriodsInfo(state  => state.periodsInfo);
const setPeriodsInfo = usePeriodsInfo(state  => state.setPeriodsInfo);


const estimatedGoal = useEstimatedGoal(state  => state.estimatedGoal);
const setEstimatedGoal = useEstimatedGoal(state  => state.setEstimatedGoal);


const estimatedCostPerConversion = useEstimatedCostPerConversion(state  => state.estimatedCostPerConversion);
const setEstimatedCostPerConversion = useEstimatedCostPerConversion(state  => state.setEstimatedCostPerConversion);


const estimatedSecondaryKpi = useEstimatedSecondaryKpi(state  => state.skpi);
const setEstimatedSecondaryKpi = useEstimatedSecondaryKpi(state  => state.setSecondaryKpi);

const frontSecondaryKpi = useFrontSecondaryApi(state => state.Kpi);

const periodValidate = usePeriodValidate(state  => state.validate);
const setPeriodValidate = usePeriodValidate(state  => state.setValidate);


const cpaValidate = useCpaValidate(state  => state.validate);
const setCpaValidate = useCpaValidate(state  => state.setValidate);

const cpcValidate = useCpcValidate(state  => state.validate);
const setCpcValidate = useCpcValidate(state  => state.setValidate);
const goal = useGoal(state  => state.goal);

const campaignEditDetails = useEditCampaignDetails(state => state.campaignDetails);

useEffect(() => {

   

   
        if(periodValidate && !isObject(campaignEditDetails))
        {

        const periodCount = periods.filter(
        obj => !(obj && Object.keys(obj).length === 0)
        ).length;

        const splitBudget = parseFloat(totalBudget / periodCount ).toFixed(3);
    //  console.log(splitBudget,"test budge")

        var arr = [];
        for(var i=0; i<periodCount; i++){
            arr.push(splitBudget)
        }


        setPeriodBudget(arr);
    
        }




}, [totalBudget]);




useEffect(()=>{

    console.log(campaignEditDetails,"cpd")
    console.log(estimatedPeriodsGoal,"testt pereos")

    if(cpaValidate  && !isObject(campaignEditDetails) ) 
    {
  
    const periodCount = periods.filter(
    obj => !(obj && Object.keys(obj).length === 0)
    ).length;

    var arr = [];
    for(var i=0; i<periodCount; i++){
        arr.push((totalEstimatedGoal/periodCount).toFixed(2))
    }


    setEstimatedPeriodsGoal(arr);


    }




},[totalEstimatedGoal]);




useEffect(()=>{

  

    setPeriodsInfo(pinfo);
    if(cpcValidate)
    {
  
    const periodCount = periods.filter(
    obj => !(obj && Object.keys(obj).length === 0)
    ).length;


    var arr = [];
    for(var i=0; i<periodCount; i++){
        arr.push(totalEstimatedCostPerConversion)
    }


    setEstimatedCostPerConversion(arr);

    }




},[totalEstimatedCostPerConversion]);


const [sortPeriodsDaysOff, setSortPeriodsDaysOff] = useState([]);


useEffect (() => {

   
 
    const pdo = periodsDaysOff;

  //  console.log(new Date(periods[0][0]).getTime() == new Date(periodsDaysOff[0]).getTime(),"get periods");
   
    let arr = [];
    let arr1 = [];
  
   
        periods.filter(
            obj => !(obj && Object.keys(obj).length === 0)
        ).map((index) => { 

            arr.push(index=0);
            
         //   test.push(index);
          

        })
    
        periods.filter(
             obj => !(obj && Object.keys(obj).length === 0)
         ).map((key, index) => { 

       
        periodsDaysOff.map((a,b) => {

            key.map((c,d) => {
             //  console.log(a,"------",c,";;;;;;",new Date(a).toDateString() == new Date(c).toDateString(),"ssperiods date");
         
              
             const checkMathDate = new Date(a).toDateString() == new Date(c).toDateString();
             
             
               if(checkMathDate)
               {
                 
                    arr[index] += 1;  
                    
                                      
                    arr1.push([a,index]);
                    

               }

              
             
            })

        })

    })
   
    

    setSortPeriodsDaysOff(arr);
    setPeriodsDaysOffInfo(arr1);



},[])






const getMonth = (m) => {
    var months = new Array(12);
    months[0] = "Jan";
    months[1] = "Feb";
    months[2] = "Mar";
    months[3] = "Apr";
    months[4] = "May";
    months[5] = "Jun";
    months[6] = "Jul";
    months[7] = "Aug";
    months[8] = "Sep";
    months[9] = "Oct";
    months[10] = "Nov";
    months[11] = "Dec";

    return months[m];
}

const addPeriodBudget = (e,i) => {


    const a = periodBudget.slice();
    a[i] = e;

  

    setPeriodValidate(false);
    setPeriodBudget(a);
}


const addEstimatedPeriodsGoal = (e,i) => {

    const a = estimatedPeriodsGoal.slice();
    a[i] = e


    setCpaValidate(false);
    setEstimatedPeriodsGoal(a);


 }

 
 const addEstimatedCostPerConversion = (e,i) => {

    const a = estimatedCostPerConversion.slice();
    a[i] = e;

    setCpcValidate(false);
    setEstimatedCostPerConversion(a);

 }
 

  
 const addEstimatedSecondaryKpi = (e,i) => {

    const a = estimatedSecondaryKpi.slice();
    a[i] = e;

   

    setCpcValidate(false);
    setEstimatedSecondaryKpi(a)

 }
 

//console.log(findMinMaxDate(),"wtf")
let sumTotalDays = 0;
let sumTotalBudget = 0;
let sumEstimatedGoal = 0;
let averageEstimatedCostPerConversion = 0;
let sumDailyBudget = 0;
let averageEstimatedSecondaryKpi = 0;
let averageEstimatedOptimization = 0;





let pinfo =[];


useEffect( ()=>{
   async function test() {
    setPeriodsInfo(pinfo);
   }
    test();
},[pinfo])

//total budget / total days

return (
    <div>
    <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between', marginTop:20, backgroundColor:'aliceblue', padding:30}}>

        
        <div style={{width:'20%', marginLeft:'.5%',marginTop:54}}>
            <div style={{padding:13, textAlign:'center'}}></div>
            <div className='periodHeader'>Days</div>
            <div className='periodHeader'>Budget</div>
            <div className='periodHeader'>Daily Budget</div>
            <div className='periodHeader'>Estimated  {goal}</div>
            <div className='periodHeader'>Estimated {optimizationKpi}</div>
            {frontSecondaryKpi.length > 0  ?
            <div className='periodHeader'>Estimated {frontSecondaryKpi}</div>
            :''}
        </div>

        <div style={{width:'80%', textAlign:'center',  overflowY:'scroll', flexDirection:'row',display:"flex"}}>


            { periods.filter(
                    obj => !(obj && Object.keys(obj).length === 0)
                ).map((key, index) => { 

                const periodCount = periods.filter(
                    obj => !(obj && Object.keys(obj).length === 0)
                ).length;


                const startDate = key[0];
                const endDate = key[key.length - 1];

                const startDay = new Date(startDate).getDate();

                const endDay = new Date(endDate).getDate();

                const difference = new Date(endDate).getTime() - new Date(startDate).getTime() + 1;

                const totalDays = Math.ceil(difference / (1000 * 3600 * 24));

                console.log(periodBudget[index],"wtis");

                const period_budget = periodBudget[index] == undefined  ||   periodBudget[index] == "" ? 0 : periodBudget[index];

                const finalDays = totalDays - sortPeriodsDaysOff[index];

                const dailyBudget = period_budget / finalDays;
            
                const startMonth = getMonth([new Date(startDate).getMonth()]);
                const endMonth = getMonth([new Date(endDate).getMonth()]);

                const estimatedOptimization = optimizationKpi == "CPM" ?  parseFloat((periodBudget[index] / estimatedPeriodsGoal[index])*1000).toFixed(2) : parseFloat(periodBudget[index] / estimatedPeriodsGoal[index]).toFixed(2);

                sumTotalDays =  sumTotalDays += finalDays ;

                sumTotalBudget +=  parseFloat(period_budget);
                    
                averageEstimatedSecondaryKpi += parseFloat(estimatedSecondaryKpi[index] / periodCount);

             //   sumDailyBudget += dailyBudget;

                const estimated_period_goal = estimatedPeriodsGoal[index] == undefined ? 0 : estimatedPeriodsGoal[index];

                sumEstimatedGoal += parseFloat(estimated_period_goal);

                averageEstimatedOptimization += parseFloat(estimatedOptimization / periodCount);

                averageEstimatedCostPerConversion += parseFloat(estimatedCostPerConversion[index] / periodCount);

               // sumDailyBudget += parseFloat()

               
               const eSecondayKpi =  estimatedSecondaryKpi[index]  ;

               const daysOffCount = sortPeriodsDaysOff[index];

               const daysOff = periodsDaysOff;

             //  console.log(dBudget,"why not")

                ///////////////////for periods info///////////////////////
                const pbudget= periodBudget[index];
                const dBudget = dailyBudget / finalDays;
                const pestimatedGoal = estimatedPeriodsGoal[index];
                const pestimatedCostPerConversion = estimatedCostPerConversion[index];              
                pinfo.push({startDate,endDate,pbudget,dailyBudget,pestimatedGoal,estimatedOptimization,estimatedSecondaryKpi,finalDays});

                //////////////////////////////////////////////////////////////////
                
           //   {console.log(pinfo)}
              
                return(

                <div style={{width:'30%' }}>
                   
                    <div className='periodHeaderMonth'>
                        {startMonth === endMonth ? startMonth : startMonth +"-"+ endMonth}
                        <br/> <span style={{fontSize:13}}>{startDay} - {endDay}</span></div>

                    <div className='periodRow'>{finalDays}</div>

                    <div className='periodRow'>
                        
                        <input type="number"  style={{width:'50%',  padding:'5px', border:'none'}}
                        value={  periodBudget[index] }
                        onChange={(e) => { addPeriodBudget(e.target.value,index)}}
                        />


                    </div>

                    <div className='periodRow'>

                    <input type="text"   style={{width:'50%',  padding:'5px', border:'none'}}
                        value={dailyBudget.toFixed(2) }
                        //onChange={(e) => { addEstimatedGoal(e.target.value,index);}}
                        disabled
                    />

                    </div>

                    <div className='periodRow'>
                    
                    <input type="number"   style={{width:'50%',  padding:'5px', border:'none'}}
                        value={estimatedPeriodsGoal[index]}
                        onChange={(e) => { addEstimatedPeriodsGoal(e.target.value,index);}}
                        
                    />

                    </div>

                    <div className='periodRow'>
                    <input type="text"   style={{width:'50%',  padding:'5px', border:'1px solid white'}}
                        value={isNaN(estimatedOptimization) ? "0.00" : isFinite(estimatedOptimization) ? estimatedOptimization : '0.00' }
                        onChange={(e) => { addEstimatedCostPerConversion(e.target.value,index);}}
                        disabled
                    />    
                    </div>    
                    {frontSecondaryKpi.length > 0  ?
                    <div className='periodRow'>
                    <input type="text"   style={{width:'50%',  padding:'5px', border:'none'}}
                        value={eSecondayKpi}
                        onChange={(e) => { addEstimatedSecondaryKpi(e.target.value,index);}}
                    />    
                    </div>   
                    :''} 

                </div>

            )})}

           


                <div style={{width:'30%' }}>
                    <div className='periodHeaderMonth'>Total <br/> <span></span></div>
                   
                    <div className='periodRow'>{sumTotalDays}</div>
                        {setPeriodTotalBudget(sumTotalBudget) }

                       
                        <div className='periodRow'>{totalBudget != sumTotalBudget ? <span style={{color:'red'}}>{(sumTotalBudget).toFixed(3)}</span> : (sumTotalBudget).toFixed(3)}</div>

                        <div className='periodRow'>{isNaN((sumTotalBudget/sumTotalDays).toFixed(2)) ? "0.00" : (sumTotalBudget/sumTotalDays).toFixed(2) }</div> 

                        {setEstimatedTotalGoal(sumEstimatedGoal)}
                        <div className='periodRow'>{totalEstimatedGoal != sumEstimatedGoal ?  <span style={{color:'red'}}>{(sumEstimatedGoal).toFixed(2)}</span> :(sumEstimatedGoal).toFixed(2) }</div>

                        <div className='periodRow'>{isNaN((averageEstimatedOptimization).toFixed(2)) ? "0.00" : isFinite((averageEstimatedOptimization).toFixed(2)) ? (averageEstimatedOptimization).toFixed(2) : '0.00'  }</div> 

                        {frontSecondaryKpi.length > 0  ?
                        <div className='periodRow'>{ isNaN((averageEstimatedSecondaryKpi).toFixed(2)) ? "0.00" : (averageEstimatedSecondaryKpi).toFixed(2) }</div> 
                        :''}

                </div>


        </div>
            
             
     


        </div>

    </div>
  )
}

export default Periods