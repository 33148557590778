import React, { useEffect } from 'react';
import { FormControl, InputLabel,Select,MenuItem, TextField} from '@material-ui/core';
import {

  useDaysOff,
  useCampaignStartDate,
  useCampaignEndDate,
  useTotalBudget,
  useOptimizationKpi,
  useTotalEstimatedGoal,
  useFrontSecondaryApi,
  useContinousSecondaryKpi,
  useContinuousDailyBudget,
  useContinuousOptimizationKpi,
  useContinousTotalDays,
  useCampaignDates,
  useEditCampaignDetails

} from '../Zustand/store';
import { isObject } from '@okta/okta-auth-js';

const Continuous = (props) => {

  const campaignStartDate = useCampaignStartDate(state => state.date);
  const campaignEndDate = useCampaignEndDate(state => state.date);

  const continousDaysOff = useDaysOff(state => state.daysOff);

  const campaignEditDetails = useEditCampaignDetails(state  => state.campaignDetails);


  const totalEstimatedGoal = useTotalEstimatedGoal(state => state.estimatedGoal);

  const continuousSecondaryKpi = useContinousSecondaryKpi(state => state.Kpi)
  const setContinuousSecondaryKpi = useContinousSecondaryKpi(state => state.setSecondaryKpi)

  const setContinuousDailyBudget = useContinuousDailyBudget(state => state.setBudget);
  const setContinuousOptimizationKpi = useContinuousOptimizationKpi(state => state.setSecondaryKpi);
  const setContinuousTotalDays = useContinousTotalDays(state => state.setDays)

  const campaignDates = useCampaignDates(state  => state.dates);
  const frontSecondaryKpi = useFrontSecondaryApi(state => state.Kpi);



  const totalBudget = useTotalBudget(state => state.totalBudget.length > 0 ? state.totalBudget.replace(/[€,]+/g,""): state.totalBudget);

  const optimizationKPI = useOptimizationKpi(state => state.Kpi);


  const daysDiff = new Date(campaignEndDate ? campaignEndDate : campaignDates[1]).getTime() - new Date(campaignStartDate ? campaignStartDate : campaignDates[0]).getTime() ;
  const totalDays = daysDiff / (1000 * 60 * 60 * 24) + 1 - continousDaysOff.length;

  
  const continuousDailyBudget = totalBudget.length > 0 ? (totalBudget/totalDays).toFixed(2)  : (totalBudget/totalDays).toFixed(2) ;

  const continuousOptimizationKpi =  optimizationKPI == "CPM" ?  totalBudget.length > 0 ?  ((totalBudget/totalEstimatedGoal)*1000).toFixed(2) : (totalBudget/totalEstimatedGoal).toFixed(2) : totalBudget.length > 0 ?  ((totalBudget/totalEstimatedGoal)).toFixed(2) : (totalBudget/totalEstimatedGoal).toFixed(2);


  setContinuousDailyBudget(continuousDailyBudget);
  setContinuousOptimizationKpi(continuousOptimizationKpi);
  setContinuousTotalDays(totalDays);

 


  return (
    <>
    <div style={{display:'flex',flexDirection:'row',alignContent:'center', marginTop:20, backgroundColor:'aliceblue', padding:30}}>

               
      <div style={{width:'60%', marginLeft:'.5%', }}>
          <div style={{textAlign:'center'}}></div>
          <div className='periodHeader'>Total Days</div>
          <div className='periodHeader'>Daily Budget</div>
          <div className='periodHeader'>Estimated {optimizationKPI}</div>

          {frontSecondaryKpi.length > 0  ?
          <div className='periodHeader' style={{padding:20}}>Estimated {frontSecondaryKpi}</div>
          : '' }
      </div>

      <div style={{width:'80%', textAlign:'center',  overflowY:'scroll', flexDirection:'row',display:"flex"}}>


      <div style={{width:'100%' }}>
                    

                    <div className='periodRowContinuous'>{Math.round(totalDays)}</div>

                    <div className='periodRowContinuous'>{continuousDailyBudget}</div>

                    <div className='periodRowContinuous'>{isNaN(continuousOptimizationKpi) ? 0 : isFinite(continuousOptimizationKpi) ? continuousOptimizationKpi : 0 }</div>

                    {frontSecondaryKpi.length > 0  ?

                    <div className='periodRowContinuous'>

                    <input type="number"   style={{width:'50%',  padding:'5px', border:'none'}}
                        value={continuousSecondaryKpi}
                        onChange={(e) => { setContinuousSecondaryKpi(e.target.value);}}
                        placeholder="Enter Secondary KPI "
                       
                        
                    />
                             
                    </div>
                    :''}

      </div>

      </div>


    </div>





      </>

  )
}

export default Continuous