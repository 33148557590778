import React , {useEffect, useState}from 'react' 
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';


import FormLabel from '@mui/material/FormLabel';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { makeStyles } from "@material-ui/core/styles";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CampaignIcon from '@mui/icons-material/Campaign';
import { Typography,Container, Grid,Item, FormControl,
    InputLabel,Select,MenuItem, TextField, Button,FormHelperText, 
    Dialog, DialogTitle, DialogActions,DialogContent,DialogContentText, CircularProgress,} from '@material-ui/core';
import {useGoogleCampaign,useDaysOff, usePeriods, usePeriodsDuration, useCampaignEndDate, useCampaignStartDate, useWeekendsOff, usePickDates,
   usePeriodsDaysOff,useTotalBudget,useTotalEstimatedGoal, useCampaignDates, useEditCampaignDetails, usePeriodBudget,useContinousSecondaryKpi,useEstimatedSecondaryKpi,useEstimatedPeriodsGoal
} from '../Zustand/store';
import { Hidden } from '@mui/material';
import Alert from '@mui/material/Alert';
import { isObject, isoToUTCString } from '@okta/okta-auth-js';


const Step2 = (props) => {


const campaignEditDetails = useEditCampaignDetails(state  => state.campaignDetails);

const googleCampaign = useGoogleCampaign(state  => state.google_campaign);
const [collapseTotalCampaign, setCollapseTotalCampaign] = useState(false);

const daysOff = useDaysOff(state  => state.daysOff);
const setDaysOff = useDaysOff(state => state.setDaysOff);

const setCampaignStartDate = useCampaignStartDate(state => state.setCampaignStartDate);
const setCampaignEndDate = useCampaignEndDate(state => state.setCampaignEndDate);

const campaignStartDate = useCampaignStartDate(state => state.date);
const campaignEndDate = useCampaignEndDate(state => state.date);

const periods = usePeriods(state  => state.periods);
const setPeriods = usePeriods(state => state.setPeriods)


const periodsDaysOff = usePeriodsDaysOff(state  => state.periodsDaysOff);
const setPeriodsDaysOff = usePeriodsDaysOff(state => state.setPeriodsDaysOff)

const periodsDuration = usePeriodsDuration(state  => state.periodsDuration);
const setPeriodsDuration = usePeriodsDuration(state => state.setPeriodsDuration);

const weekendsOff = useWeekendsOff(state  => state.weekends);
const setWeekendsOff = useWeekendsOff(state => state.setWeekendsOff);

const pickDates = usePickDates(state  => state.dates);
const setPickDates = usePickDates(state => state.setPickDates);

const [selectedDate, setSelectedDate] = useState([]);

const setTotalBudget = useTotalBudget(state => state.setTotalBudget);

const setEstimatedGoal = useTotalEstimatedGoal(state => state.setEstimatedGoal);

const setCampaignDates = useCampaignDates(state => state.setDates);

const[error,setError] =useState(false);
const[errorOverlap,setErrorOverlap] =useState(false);

const [openOverlap, setOpenOverlap] = useState(true);

const setPeriodBudget = usePeriodBudget(state => state.setPeriodBudget);

const [startPeriodDate, setStartPeriodDate] = useState();
const [endPeriodDate, setEndPeriodDate] = useState();

const setContinuousSecondaryKpi = useContinousSecondaryKpi(state => state.setSecondaryKpi);

const setEstimatedSecondaryKpi = useEstimatedSecondaryKpi(state  => state.setSecondaryKpi);


const setEstimatedPeriodsGoal = useEstimatedPeriodsGoal(state  => state.setEstimatedPeriodsGoal);


useEffect(()=>{
  window.scrollTo({  top: 0,left: 0, behavior: "smooth" });
},[])


const validate = ()  => {


   //alert("from step 2")

   if(pickDates == "continuous"){

    if( isObject(campaignEditDetails)  ){

    setTotalBudget(String(campaignEditDetails.total_budget));
    setEstimatedGoal(String(campaignEditDetails.estimated_goal));
    setContinuousSecondaryKpi(campaignEditDetails.periods_info[0].estimated_secondary_optimaization);
    
    }


    return true;
   }
   else{

        if(periods.length > 0){

          
          if( isObject(campaignEditDetails)  ){

            setTotalBudget(String(campaignEditDetails.total_budget));
            setEstimatedGoal(String(campaignEditDetails.estimated_goal));

            const eskpi = [];
            const eg = [];
            const p = [];
            campaignEditDetails.periods_info.map((a,b) =>{       
               p.push(a.total_budget);
               eskpi.push(a.estimated_secondary_optimaization);
               eg.push(a.estimated_goal);
              
            })
            
              setPeriodBudget(p);
              setEstimatedSecondaryKpi(eskpi);
              setEstimatedPeriodsGoal(eg);
          

          }



          return true;

        }
        else{
          
          setError(true);
          return false;
        
        }
   }

  

}

const collapse = ()  => {
    
    //alert("from step 2")
    
    let checkStatus =''; 
    if(collapseTotalCampaign  == true){
        checkStatus = false
    }
    else{
        checkStatus = true;
    }


    
     setCollapseTotalCampaign(checkStatus);
 
 }
 


props.step2Ref.current = {
    validate: validate
};




const findMinMaxDate = () =>{

  

    const arr = [];

      googleCampaign.map((key,index)=>{
        
        arr.push(new Date(googleCampaign[index].start_date),new Date(googleCampaign[index].end_date))

      });

  
      
      const min = new Date(Math.min(...arr));
   //  console.log(min,"min date"); // 👉️ Sat Sep 24 2022
      
      const max = new Date(Math.max(...arr));
   //  console.log(max,"max date"); // 👉️ Thu Jul 17 2025

      return [min,max];

} 

const [minStartDate,maxStartDate] = findMinMaxDate();


const [getstartDate,setGetStartDate] = useState( isObject(campaignEditDetails) ? campaignEditDetails.start_date : campaignStartDate === "" ? minStartDate.toDateString() : new Date(campaignStartDate).toDateString() );
const [getendDate,setGetEndDate] = useState(isObject(campaignEditDetails) ? campaignEditDetails.end_date : campaignEndDate === "" ?    maxStartDate.toDateString() : new Date(campaignEndDate).toDateString());
//const [getstartDate,getendDate] = findMinMaxDate();


const getDatesBetweenDates = (startDate, endDate) => {
  let dates = []
  //to avoid modifying the original date
  const theDate = new Date(startDate)
  while (theDate < new Date(endDate)) {
    dates = [...dates, new Date(theDate)]
    theDate.setDate(theDate.getDate() + 1)
  }
  dates = [...dates, new Date(endDate)]
  return dates
}


const test = () => {

  if(isObject(campaignEditDetails)){

     

        let result = [];

        campaignEditDetails.periods_info.map((a,b)=>{

         

          const d = getDatesBetweenDates(a.start_date,a.end_date)

          

          result.push(d)
        
        })

       return result;

      
      }


}


 useEffect(()=>{


    


    //setting initail campaigns date
    setCampaignDates([getstartDate,getendDate]);


    if(isObject(campaignEditDetails)) {
      
      setPickDates(campaignEditDetails.mode);

      const dt = test();
      setPeriods(dt);


      let doa = [];

      campaignEditDetails.periods_info.map((a,b)=>{

            a.days_off.map((c,d) => {
             
              doa.push( new Date(c.date));
              
            })


      })

      setPeriodsDaysOff(doa)
      
    
    
    };
    //setCampaignStartDate(getstartDate);
    //setCampaignEndDate(getendDate);


    
              
  
   


   // setPeriods([dt])

   


 },[])



 const handleStartDate = (e) =>{

  const sdate = e.target.value;

  setGetStartDate(new Date(sdate).toISOString());
  setCampaignStartDate(new Date(sdate).toISOString().slice(0,10));

 }


 const handleEndDate = (e) =>{

  const sdate = e.target.value;

  setGetEndDate(new Date(sdate).toISOString());
  setCampaignEndDate(new Date(sdate).toISOString().slice(0,10));

 }


 const validateDate = (e) => {

   
    setDaysOff([...daysOff,new Date(e)]);


    
     daysOff.map((key,index) =>{

   

      if(new Date(daysOff[index]).getTime() == new Date(e).getTime())
        {
         
           setDaysOff([...daysOff.slice(0,index), ...daysOff.slice(index + 1)])

        }
       

    } )

   
   
 }

 const validateDatePeriods = (e) => {


    periods.map((key,index) =>{
      
      
        key.map((a,b)=>{
           // console.log(new Date(a),"a");
            if(new Date(a).toDateString() == new Date(e[0]).toDateString())
            {
             //  console.log(periods[index][b],'get')
               // setPeriods([...periods[index][b].slice(0,b), ...periods[index][b].slice(b + 1)])

              setPeriodsDaysOff([...periodsDaysOff,new Date(a)]);
              
    
            }
            else{
               // console.log("no date")
            }

        })
       
       

    } )

   
   
 }



 

 const [startDate, setStartDate] = useState('');
 const [endDate, setEndDate] = useState(null);


 const addPeriods = (dates) => {
  
    
   const [start, end] = dates;
   setStartDate(start);
   setEndDate(end);

   addPeriodsDates(dates);
   //console.log(dates,"check reange")
 };

 //const [daysHighlighted, setDaysHighlighted ] = useState([new Date("10-11-2022"),new Date("10-12-2022")]);



 const addPeriodsDates = (dates) =>{

   // console.log(dates,"check periods dates");

   // setPeriodsDuration([...periodsDuration, dates])
    
    let result = [], current = new Date(dates[0]);
    while (current <= dates[1])
        result.push(current) && (current = new Date(current)) && current.setDate(current.getDate() + 1);

       
    setPeriods([...periods,result]);
    
 }

 const resetPeriod = () => {


      if(periods.length > 0) {

      let isExecuted = window.confirm("Are you sure you want to reset the periods?");


      if(isExecuted){
      // setDaysHighlighted([]);
        setError(false);
        setErrorOverlap(false);
        setStartDate('')
        setEndDate('')
        setPeriods([]);
        setPeriodsDaysOff([]);
      }
    }
  


 }

 const showPeriods = () =>{


  

    let arr = [];

    periods.map((k,i)=>{
        k.map((a,b)=>{
            arr.push(new Date(a));
        })
    })

   
    return arr;
  
 }
 


 //const [alignment, setAlignment] = React.useState('continuous');

  const handlePickDates = (event, newAlignment) => {
   
    setPickDates(newAlignment);
    setTotalBudget([]);
    setEstimatedGoal([]);

    
 
  };

//const [weekends,setWeekends] = useState('no');
  const handleWeekends = (event, newAlignment) => {

   // console.log(new Date(getstartDate).getDay(),"==========", new Date(getendDate), "Get me the dates");


    setWeekendsOff(newAlignment);
 
    if(newAlignment == "yes"){

    var a  = findWeekends(new Date(getstartDate),new Date(getendDate));
    //setDaysOff(...daysOff,a)

     a.map((key,index) =>{

     
            setDaysOff([...a.slice(0,index), ...a.slice(index + 1)])
       

    } )
}
else{

    const weekends  = findWeekends(new Date(getstartDate),new Date(getendDate));
    weekends.map((key,index) =>{
       
        setDaysOff([...daysOff, ...daysOff.splice(daysOff,1)]);


    })
    }

  
  };

  const findWeekends = (dDate1,dDate2) => {
    if (dDate1 > dDate2) return false;
    var date  = dDate1;
    var dates = [];

    while (date < dDate2) {
       
        if (date.getDay() === 6 || date.getDay() === 0) dates.push(new Date(date));
        date.setDate( date.getDate() + 1 );
    }
    
    return dates;
        
  }


const [open, setOpen] = React.useState(false);


const inputPeriods = () => {
   handleClickOpen();
   
  };
 
 const handleClickOpen = () => {
    setOpen(true);
  };
 
  const handleClose = () => {
    setOpen(false);
  //  window.location.reload();
 
  };
  
  const addStartDatePeriods = (e) => {

   
   
    setStartPeriodDate(e)
 
  };

  const addEndDatePeriods = (e) => {
   
    setEndPeriodDate(e)
 
  };

  function checkPeriodsOverlap(result) {

    let res;

    result.map((a,b) => {

      periods.map((c,d)=>{
          
          c.map((e,f)=>{

             if(new Date(a).getTime() == new Date(e).getTime())
             {
                res = true;
             }
            
           
          })


      })


    })

    return res;
   
  }

  const validatePeriods = () => {

  

    setError(false);
    setOpen(false);


    

    let result = [], current = new Date(startPeriodDate);
    while (current <= endPeriodDate)
        result.push(current) && (current = new Date(current)) && current.setDate(current.getDate() + 1);


    if(periods.length > 0 ){
      
      const overlap = checkPeriodsOverlap(result);

      if(overlap){
        setErrorOverlap(true);
      }
      else{
        setErrorOverlap(false);
        setPeriods([...periods, result]);
      }
     


    }
    else{

     setPeriods([...periods, result]);

    }


  };


  useEffect(()=>{

  


    if(isObject(campaignEditDetails))
    {

      let ep=[];
      campaignEditDetails.periods_info[0].days_off.map((a,b)=>{

      ep.push(new Date(a.date))
        


      })

     setDaysOff(ep);

    }

  },[])

  const resetDaysOffContinuous = () => {
    let isExecuted = window.confirm("Are you sure you want to reset the days off ?");


    if(isExecuted){
      setDaysOff([]);
    }
  }



  return (
   <div className="card-body" >

   

    <div>
    <table width="100%" style={{border:'1px solid lightgray'}}>
                                                      <tbody >
                                                         <tr>
                                                            <td>
                                                              
                                                       
                                                                 <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                                                                  <div> <b>Total Campaings <span style={{fontSize:'15px'}}>({googleCampaign.length})</span> </b></div>
                                                                  <div>

                                                                    <div ><b>Start Date: &nbsp;&nbsp;</b>
                                                                   
                                                                    <input style={{padding:5}} type="date" onChange={handleStartDate} value={moment.utc(new Date(getstartDate )).local().format("YYYY-MM-DD")} onkeypress="return false" onkeydown="return false"/>
                                                                      
                                                                    &nbsp;&nbsp; | &nbsp;&nbsp; <b>End Date: &nbsp;&nbsp;</b>   
                                                                    
                                                                    <input style={{padding:5}} type="date" onChange={handleEndDate} value={moment.utc(new Date(getendDate)).local().format("YYYY-MM-DD")} onkeypress="return false" onkeydown="return false"/>
                                                                      
                                                                      
                                                                      
                                                                    </div>



                                                                  </div>

                                                                 </div>
                                                              
                                                            </td>                                                            
                                                         </tr>
                                                         <tr >
                                                            <td>
                                                               <div style={{height:'200px', overflow:'auto'}}>
                                                                  <table style={{width:'100%',border:'1px solid lightgray', height:'200px', overflowY:'scroll'}}>
                                                                     <thead>
                                                                     <tr style={{border:'1px solid lightgray'}}>
                                                                           <td style={{border:'1px solid lightgray', }}>#</td>
                                                                           <td style={{border:'1px solid lightgray',width:'60%', fontWeight:'900' }}>Campaign Name</td>
                                                                           <td style={{border:'1px solid lightgray',fontWeight:'900'  }}>Stard Date</td>
                                                                           <td style={{border:'1px solid lightgray',fontWeight:'900'  }}>End Date</td>
                                                                        </tr>
                                                                     </thead>
                                                                     <tbody>

                                                                     {googleCampaign.map((key,index)=>{

                                                                        let sdate = new Date(googleCampaign[index].start_date);
                                                                       
                                                                        sdate = moment.utc(new Date(sdate )).local().format("YYYY-MM-DD");
                                                                       

                                                                        let edate = new Date(googleCampaign[index].end_date);
                                                                        edate = moment.utc(new Date(edate )).local().format("YYYY-MM-DD")


                                                                        return(
                                                                        <tr style={{border:'1px solid lightgray', height:'5%'}}>
                                                                           <td style={{border:'1px solid lightgray', }}>{index + 1}.</td>
                                                                           <td style={{border:'1px solid lightgray',width:'60%' }}>{googleCampaign[index].metagroup_name}</td>
                                                                           <td style={{border:'1px solid lightgray', }}>{sdate}</td>
                                                                           <td style={{border:'1px solid lightgray', }}>{edate}</td>
                                                                        </tr>
                                                                        )
                                                                        
                                                                        })}
                                                                      
                                                                     </tbody>
                                                                  </table>
                                                               </div>
                                                            </td>
                                                         </tr>
                                                      </tbody>
                                                   </table>
    </div>

   <div style ={{marginTop:'15px', display:'flex', flexDirection:'row', justifyContent:'left', padding:'10px', border:'1px solid lightgray'}}>
  
   <div style={{paddingRight:'20px', borderRight:'1px solid lightgray'}}> 
    
                                                       
   <FormControl>
      <FormLabel id="demo-row-radio-buttons-group-label" style={{fontWeight:900}}>Pick Dates:</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        onChange={handlePickDates}
        value={pickDates}
      >
        {isObject(campaignEditDetails) ?  campaignEditDetails.mode == "continuous" ?  <FormControlLabel value="continuous" control={<Radio />} label="By Continuous" /> : ''  : <FormControlLabel value="continuous" control={<Radio />} label="By Continuous" /> }
        {isObject(campaignEditDetails) ?  campaignEditDetails.mode == "periods" ? <FormControlLabel value="periods" checked control={<Radio />} label="By Periods" />: ''  : <FormControlLabel value="periods" control={<Radio />} label="By Periods" /> }
     
       
      </RadioGroup>
      </FormControl>
   
    </div> 

    {pickDates == "continuous" ?
    <div style={{paddingLeft:'20px'}}>

    <FormControl>
      <FormLabel id="demo-row-radio-buttons-group-label" style={{fontWeight:900}}>Weekends Off:</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        onChange={handleWeekends}
        value={weekendsOff}
      >
        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
        <FormControlLabel value="no" control={<Radio />} label="No" />
     
       
      </RadioGroup>
      </FormControl>
                                                        
 
  
  
    </div>
    :""}

<div style={{paddingLeft:'20px', borderLeft:'1px solid lightgray', marginTop:25}}> 
{isObject(campaignEditDetails) && campaignEditDetails.mode == "continuous" && daysOff.length > 0?                                                                  
<button onClick={resetDaysOffContinuous}   style={{backgroundColor:'red',  borderRadius:'6px',border:'none', color:'white',padding:'5px', marginBottom:'15px', width:'120px'}}>Reset Days Off</button>
:''}

</div>

   </div>

    
    {pickDates == "continuous" ?
    <>
    <div className="calendarWrapper">


    <div style={{overflow:'scroll', backgroundColor:'white'  }}>   

   


  
                                                                              
                    <DatePicker                 
                    inline
                    startDate={new Date(getstartDate)}
                    endDate={new Date(getendDate)}
                    selectsRange
               
                    monthsShown={12}
                    minDate={new Date(getstartDate)}
                    maxDate={new Date(getendDate)}
                    //highlightDates={highlightWithRanges}
                    highlightDates={[
                                {
                                    "highlighted-class": daysOff.map((day, index) =>{ 
                                         return new Date(day)
                                    }
                                    )
                                }
                                ]}
                    onChange={(e)=>setSelectedDate( validateDate(e))}
                   
                    
                    />
                </div>
                    
                  
        </div>
        </>
        :""}    



    {pickDates == "periods" ? 
    <>
    {error ? <Alert severity="error" color="error" variant='filled' style={{marginTop:10}}>Please Add Periods Dates </Alert> : ""}
    {errorOverlap ? <Alert onClose={() => { setOpenOverlap(false);}} severity="error" color="error" variant='filled' style={{marginTop:10}}>Periods Date Overlap, Please Choose Another Date</Alert> : ""}
    <div  className="calendarWrapperPeriods">

   
     
        
                <div style={{display:'flex', justifyContent:'space-between', flexDirection:'row'}}>

                  <div>
                 <button onClick={inputPeriods}  style={{backgroundColor:'#4169e1', borderRadius:'6px', border:'none', color:'white',padding:'5px', marginBottom:'15px',  width:'95px'}}>Add Periods</button>
                 </div>


                 <div>

                 <button onClick={resetPeriod}   style={{backgroundColor:'red',  borderRadius:'6px',border:'none', color:'white',padding:'5px', marginBottom:'15px', width:'95px'}}>RESET</button>
                
                </div>
                
                   
                </div>         
                 

                    <div style={{ padding:'20px', overflow:'scroll', backgroundColor:'white'  }}>      

                    {/**
                    <DatePicker         
                    selected={startDate}
                    minDate={new Date(getstartDate)}
                    maxDate={new Date(getendDate)}
                    onChange={addPeriods}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                    inline
                    monthsShown={12}
                    //highlightDates={[new Date("10-14-2022"),new Date("10-15-2022")]}
                    highlightDates={showPeriods()}
                    style={{width:'100%'}}
               
                    /> */}

            
                    <DatePicker                 
                    inline
                   
                    selectsRange
                    excludeDates={periodsDaysOff}
                    monthsShown={12}
                    minDate={new Date(getstartDate)}
                    maxDate={new Date(getendDate)}
                    highlightDates={showPeriods()}        
                    onChange={(e)=>validateDatePeriods(e)}
                   
                    
                    />
                     
                    
                  

                    </div>




          <Dialog
            open={open}
            onClose={handleClose}
          >
       
            <div style={{marginTop:"1%",padding:'4%', height:'290px' }}>

                
                <div style={{display:'flex', flexDirection:'row', justifyContent:'center'}}>

                <div style={{backgroundColor:'aliceblue', padding:10, margin:10, }}>
                    
                 <label>Start Period Date:</label>
                  <DatePicker 
                  placeholderText="Choose Start Date"
                  selected={startPeriodDate}
                  onChange={(e)=>addStartDatePeriods(e)} 
                  minDate={new Date(getstartDate)}
                  maxDate={new Date(getendDate)}
                  popperClassName="some-custom-class"
                    popperPlacement="bottom"
                    popperModifiers={[
                        {
                        name: "offset",
                        options: {
                            offset: [1],
                        },
                        },
                        {
                        name: "preventOverflow",
                        options: {
                            rootBoundary: "viewport",
                            tether: false,
                            altAxis: true,
                        },
                        },
                    ]}


                  />  
                 </div>

               
                <div style={{backgroundColor:'aliceblue', padding:10, margin:10}}>   
                 <label>End Period Date:</label>
                 <DatePicker
                 placeholderText='Choose End Date'
                 selected={endPeriodDate}
                 onChange={(e)=>addEndDatePeriods(e)} 
                 minDate={new Date(getstartDate)}
                 maxDate={new Date(getendDate)}
                 popperClassName="some-custom-class"
                    popperPlacement="bottom"
                    popperModifiers={[
                        {
                        name: "offset",
                        options: {
                            offset: [1],
                        },
                        },
                        {
                        name: "preventOverflow",
                        options: {
                            rootBoundary: "viewport",
                            tether: false,
                            altAxis: true,
                        },
                        },
                    ]}
                 />  
                 </div>     
                </div>

             
               
                
                

            </div>

            <div style={{display:'flex', justifyContent:'center', marginBottom:30}}>

                <Button variant='contained' onClick={validatePeriods} style={{backgroundColor:'#4169e1', color:'white'}}>Add Periods</Button>

            </div>


         
          
            </Dialog>

                  
                  
        </div>
        </>
        :""}          


          
 </div>

  )
}

export default Step2