import React, { useEffect, useState, useRef,  } from 'react'


import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';
import Step4 from './step4';
import Step5 from './step5';
import AppBar from '../Layout/appBar';


import Header from '../Layout/header';
import Footer from '../Layout/footer';



const AddCampaign = () => {
const[stepperStep,setStepperStep] = useState(0);
const[checkStep,setCheckStep] = useState(1);



const steps = [
    'Input & Import',
    'Calendar',
    'Budget & Objectives',
    'Campaign Ownership',
    'Final Confirmation'
  ];


let step1Ref = useRef(null);
let step2Ref = useRef(null);
let step3Ref = useRef(null);
let step4Ref = useRef(null);
let step5Ref = useRef(null);

const validate = () => {

   const proceed  = step1Ref.current.validate();





   if(checkStep == 1 ){
    if(proceed){
   // console.log(stepperStep,"check step")
    setStepperStep(stepperStep  +1);
    setCheckStep(checkStep + 1);
    }
    else{
      return false;
    }

   }
   else if(checkStep == 2){
    const step2 =  step2Ref.current.validate();
       if(step2){
        setStepperStep(stepperStep  +1);
        setCheckStep(checkStep + 1);
       }
   }
   else if(checkStep == 3){
    const step3 =  step3Ref.current.validate();
       if(step3){
        setStepperStep(stepperStep  +1);
        setCheckStep(checkStep + 1);
       }
   }
   else if(checkStep == 4){
    const step4 =  step4Ref.current.validate();
       if(step4){
        setStepperStep(stepperStep  +1);
        setCheckStep(checkStep + 1);
       }
   }
 
 
   

}

const goBack = () => {
  setCheckStep(checkStep - 1);
  setStepperStep(stepperStep -1);
}

const confirm = () => {
    const proceed  = step5Ref.current.submit();
}



  return (
    <>
    <Header/>
    <div className="main-content">
      
       <AppBar />
        
        <main>

        <div className="recent-grid-add-campaign">

            <div className="projects">

                
                <div className="card" >
                    <div className="card-header">
                  
                                    <Stepper activeStep={stepperStep} alternativeLabel style={{width:'100%', padding:"2rem"}}>
                                        {steps.map((label) => (
                                        <Step key={label}>
                                    
                                            <StepLabel>

                                            {label}
                                                                               
                                            </StepLabel>  
                                        </Step>
                                        ))}
                                    </Stepper>                
                     </div>
                                     
         
                   {checkStep == 1 ?  <Step1 step1Ref={step1Ref}  /> : '' }
                   {checkStep == 2 ?  <Step2 step2Ref={step2Ref}  /> : '' }
                   {checkStep == 3 ?  <Step3 step3Ref={step3Ref}  /> : '' }
                   {checkStep == 4 ?  <Step4 step4Ref={step4Ref}  /> : '' }
                   {checkStep == 5 ?  <Step5 step5Ref={step5Ref}  /> : '' }

                        
                </div>

              
                <div className="card-header" style={{backgroundColor:'white',  marginTop:'5px'}}>
                {checkStep == 1 ?     
                    <span ></span>
                    : '' }


                {checkStep != 1 ?     
                    <button onClick={goBack} className="btnNext">
                         Back
                        <i className="fa-solid fa-angles-right" style={{color:'white', fontSize:'15px', padding:'5px', cursor:'hand'}} ></i>
                    </button>
                    : '' }


                    {checkStep != 5 ?         
                 
                    <button onClick={validate}  >
                         Next
                        <i className="fa-solid fa-angles-right" style={{color:'white', fontSize:'15px', padding:'5px',}} ></i>
                    </button>
                  
                    :''}

                    {checkStep == 5 ?         
                    <button onClick={confirm} className="btnNext">
                         Confirm
                        <i className="fa-solid fa-angles-right" style={{color:'white', fontSize:'15px', padding:'5px'}} ></i>
                    </button>
                    :''}
                 
     
                 </div>



            </div>
        </div>

        </main>
    </div>
   <Footer/>
    </>
  )
}

export default AddCampaign
