import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import { Route, useHistory, Switch } from 'react-router-dom';
import ReactDOM from "react-dom";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import Api from '../Service/network';
import moment from 'moment';
import LinearProgress from '@mui/material/LinearProgress';


const muiCache = createCache({
  key: "mui-datatables",
  prepend: true
});

export default function Listing({check,count}) {
  const [responsive, setResponsive] = useState("vertical");
  const [tableBodyHeight, setTableBodyHeight] = useState("600px");
  const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("700px");
  const [searchBtn, setSearchBtn] = useState(true);
  const [downloadBtn, setDownloadBtn] = useState(true);
  const [printBtn, setPrintBtn] = useState(true);
  const [viewColumnBtn, setViewColumnBtn] = useState(true);
  const [filterBtn, setFilterBtn] = useState(true);

  const [listing, setListing] = useState([]);

  const [listingReload, setListingReload] = useState();
  const [budgetListing, setBudgetListing] = useState([]);
  const[budgetLoading,setBudgetLoading] = useState(false);
  const[loading,setLoading] = useState(false);


  const[campaignStatus, setCampaignStatus] = useState('green');

  const history = useHistory();

    useEffect(()=>{

       

        setLoading(true);

        const getUserEmail = JSON.parse(localStorage.getItem('user_details'));

        const accountsEndpoint = "https://mediafy-backend-dot-groupm-dev-dev.nw.r.appspot.com/v1/subCampaign/email/" + getUserEmail.email;
       
        Api(accountsEndpoint,"GET")
        .then(result => {
    
          
         
         // console.log(result.data,"get me data");
         
       

          setListing(result.data);
          setLoading(false);



         }).catch((err)=>console.log(err))
    
      

    },[listingReload]);


    useEffect(()=>{

       

      setBudgetLoading(true);

      const getUserEmail = JSON.parse(localStorage.getItem('user_details'));

      const accountsEndpoint = "https://mediafy-backend-dot-groupm-dev-dev.nw.r.appspot.com/v1/subCampaign/budget/" + getUserEmail.email;
     
      Api(accountsEndpoint,"GET")
      .then(result => {
  
        
   
      //  console.log(result.data,"get budget listing");

          setBudgetListing(result.data);
          setBudgetLoading(false);



       }).catch((err)=>console.log(err))
  
    

  },[]);


  const deleteCampaign = (id) =>{

    //console.log(e);


    let isExecuted = window.confirm("Are you sure you want to delete this Sub Campaign?");


    if(isExecuted){

    const endpoint = "https://mediafy-backend-dot-groupm-dev-dev.nw.r.appspot.com/v1/subCampaign/delete/"+id;
   // setClientLoading(true);
     Api(endpoint,"DELETE")
    .then(result => {
        
      setListingReload([])
        //setC([]);
      
     }).catch((err)=>console.log(err))
    }

  }


  const columns = [
   /* {
        name: "",
        options: {
          setCellProps: () => ({ style: { maxWidth: "400px",  }}), 
          setCellHeaderProps: () => ({ style: { backgroundColor: '#4169e1', color:'white',  }}),
          filter: false,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex) => {
            return (
              <div style={{width:20,height:20,backgroundColor:campaignStatus, borderRadius:50}}></div>
            );
          }
        }
      },
     {
        name: "",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex) => {
            return (
              <a onClick={() => {
               
                alert(data);
              }}>
              <span className="las la-search-location" style={{cursor:'pointer', marginLeft:5}}></span> 
              </a>
            );
          }
        }
      },*/
    { name: "Campaign Name", options: {
        filter:false,
        setCellProps: () => ({ style: { maxWidth: "300px", width:700  }}), 
        setCellHeaderProps: () => ({ style: { backgroundColor: '#4169e1', color:'white',  }}),
        
        }, 
        
    },

    { name: "€ Budget Left", options: {
      
       // setCellProps: () => ({ style: { maxWidth: "400px",  }}), 
        setCellHeaderProps: () => ({ style: {  backgroundColor: '#4169e1', color:'white',}}),
       
       
        }, 
    },

    { name: "€ Total Budget", options: {
       // setCellProps: () => ({ style: { maxWidth: "400px",  }}), 
        setCellHeaderProps: () => ({ style: {  backgroundColor: '#4169e1', color:'white',}}),
       
        
       
        }, 
    },
    { name: "Advertiser", options: {
      // setCellProps: () => ({ style: { maxWidth: "400px",  }}), 
       setCellHeaderProps: () => ({ style: {  backgroundColor: '#4169e1', color:'white',width:'200px'}}),
      
       
      
       }, 
   },
   { name: "Master Campaign", options: {
    // setCellProps: () => ({ style: { maxWidth: "400px",  }}), 
     setCellHeaderProps: () => ({ style: {  backgroundColor: '#4169e1', color:'white', width:'200px'}}),
    
     
    
     }, 
 },

    { name: "Start Date", options: {
       // setCellProps: () => ({ style: { maxWidth: "10px", color:'#4169e1' }}), 
        
        setCellHeaderProps: () => ({ style: { backgroundColor: '#4169e1', color:'white' ,}}),
        customBodyRender: value => {
            return <span style={{padding:5}}><i className="las la-calendar"></i> {value}</span>
          }
        }, 
    },


    { name: "End Date", options: {

     //  setCellProps: () => ({ style: { maxWidth: "10px", color:'red'  }}), 
        setCellHeaderProps: () => ({ style:  { backgroundColor: '#4169e1', color:'white',  }}),
        
       customBodyRender: value => {
            return <span style={{padding:5}}><i className="las la-calendar"></i> { value }</span>
          }
        
        }, 
    }, 
    {name: "campaign id",  options: {display: false, viewColumns: false, filter: false}},
  //  {name: "client",  options: {display: false, viewColumns: false, filter: false}},
    {name: "campaign owner",  options: {display: false, viewColumns: false, filter: false}},
    {name: "platform",  options: {display: false, viewColumns: false, filter: false}},
    {name: "goal",  options: {display: false, viewColumns: false, filter: false}},
    {name: "team leader",  options: {display: false, viewColumns: false, filter: false}},
    {name: "performance manager",  options: {display: false, viewColumns: false, filter: false}},
    {name: "campaign backup",  options: {display: false, viewColumns: false, filter: false}},
    {name: "campaign type",  options: {display: false, viewColumns: false, filter: false}},
    {name: "optimization kpi",  options: {display: false, viewColumns: false, filter: false}},
    {name: "secondary kpi",  options: {display: false, viewColumns: false, filter: false}},
 //   {name: "master campaign",  options: {display: false, viewColumns: false, filter: false}},
    {name: "master campaign id",  options: {display: false, viewColumns: false, filter: false}},
    {name: "campaign",  options: {display: false, viewColumns: false, filter: false}},
    {name: "estimated goal",  options: {display: false, viewColumns: false, filter: false}},
    {name: "start date",  options: {display: false, viewColumns: false, filter: false}},
    {name: "end date",  options: {display: false, viewColumns: false, filter: false}},
    {name: "modes",  options: {display: false, viewColumns: false, filter: false}},
    {name: "periodsInfo",  options: {display: false, viewColumns: false, filter: false}},
    {name: "notes",  options: {display: false, viewColumns: false, filter: false}}, //256row
    {name: "periods info",  options: {display: false, viewColumns: false, filter: false}}, //256row
    {name: "subcampaign",  options: {display: false, viewColumns: false, filter: false}},
  {
      name: "",
      options: {
        setCellProps: () => ({ style: { maxWidth: "400px",  }}), 
        setCellHeaderProps: () => ({ style: { backgroundColor: '#4169e1', color:'white',  }}),
        filter: false,
        viewColumns:false,
        sort: false,
        empty: true,
        customBodyRenderLite: (dataIndex) => {
          return (
            <a onClick={(e) => {
              e.stopPropagation()
              editCampaign(data[dataIndex][7]);
            // console.log(data[dataIndex][3],"afds");
            }}>
            <span className="las la-edit" style={{cursor:'pointer', marginLeft:5, fontSize:20}} ></span> 
            </a>
          );
        }
      }
    },
   
  {
      name: "",
      options: {
        setCellProps: () => ({ style: { maxWidth: "400px",  }}), 
        setCellHeaderProps: () => ({ style: { backgroundColor: '#4169e1', color:'white',  }}),
        filter: false,
        viewColumns:false,
        sort: false,
        empty: true,
        customBodyRenderLite: (dataIndex) => {
          return (
            <a onClick={(e) => {
              e.stopPropagation()
              deleteCampaign(data[dataIndex][7]);
            // console.log(data[dataIndex][3],"afds");
            }}>
            <span className="las la-trash" style={{cursor:'pointer', marginLeft:5, fontSize:20}} ></span> 
            </a>
          );
        }
      }
    },
    


    ];

     
  const options = {
    search: searchBtn,
    download: downloadBtn,
    print: printBtn,
    viewColumns: viewColumnBtn,
    filter: filterBtn,
    filterType: "multiselect",
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    selectableRows: 'none',
    sortOrder: {
        name: 'name',
        direction: 'desc'
    },
    responsive: "standard",
    onRowClick: rowData => {check(rowData)}//console.log(rowData,"check data") }


,

//console.log(rowData,"check data") }

   /*
   onCellClick: (rowData, rowIndex) => {

    if(rowIndex.colIndex === 0){
      
     console.log(rowData, "---", rowIndex)

    }
  }*/
  
     
  };

  {/**
  const dataa = [
    ["Ikea Summer collection", "Jan 4 2022","Dec 4 2023","1000$", "Active"],
    ["Ikea Winter collection", "Jan 7 2022","Dec 4 2023","1000$", "Active"],
    ["Ikea Autum collection", "Jan 3 2022","Dec 4 2023","1000$", "Active"],
    ["Ikea Spring collection", "Jan 4 2022","Dec 4 2023","1000$", "Active"],
    ["Ikea Rainy collection", "Jan 2 2022","Dec 4 2023","1000$", "Active"],
    ["Ikea Summer collection", "Jan 13 2022","Dec 4 2023","1000$", "InActive"],
    ["Ikea Winter collection", "Jan 4 2022","Dec 4 2023","1000$", "Active"],
    ["Ikea Rainy collection", "Jan 9 2022","Dec 4 2023","1000$", "Active"],
    ["Ikea Spring collection", "Jan 4 2022","Dec 4 2023","1000$", "Active"],
    ["Ikea Autum collection", "Jan 4 2022","Dec 4 2023","1000$", "Active"],
    ["Ikea Winter collection", "Jan 12 2022","Dec 4 2023","1000$", "Active"],
    ["Ikea Summer collection", "Jan 4 2022","Dec 4 2023","1000$", "Active"],
    ["Ikea Rainy collection", "Jan 17 2022","Dec 4 2023","1000$", "Active"],
    ["Ikea Summer collection", "Jan 20 2022","Dec 4 2023","1000$", "InActive"],
    ["Ikea Summer collection", "Jan 28 2022","Dec 4 2023","1000$", "Active"],
  ]; */}


  
  const data = listing.map((a,b)=> {return([
    a.sub_campaign_name.substring(0,23) + " ....",
   // isNaN(parseFloat(a.total_budget  - a.budgetspend).toFixed(2)) ?  0 : parseFloat(a.total_budget - a.budgetspend).toFixed(2) ,
    budgetLoading ?  <LinearProgress style={{width:'20%', marginLeft:'20px'}}/>  :  isNaN(parseFloat(a.total_budget - budgetListing[b].budget).toFixed(2)) ? 0 : parseFloat(a.total_budget - budgetListing[b].budget).toFixed(2),
    a.total_budget,
    a.Client,
    a.master_campaign_name,
    moment.utc(new Date(a.start_date)).local().format("YYYY-MM-DD"), 
    moment.utc(new Date(a.end_date)).local().format("YYYY-MM-DD"), 
    a._id, 
    a.campaign_owner,
    a.platform,
    a.goal,
    a.team_leader,
    a.performance_manager,
    a.campaign_backup,
    a.campaign_type,
    a.opt_kpi,
    a.secondary_kpi,
    a.master_campaign_id,
    a.campaigns,
    a.estimated_goal,
    a.start_date,
    a.end_date,
    a.mode,
    a.mode == "periods" ? a.periods_info : '',
    a.note,
    a.periods_info, 
    a.sub_campaign_name   //to send full subcampaign stirng
    
    
  ])});


  const editCampaign = (id) =>{

    history.push({
      pathname: '/addCampaign',
      search: '?cid='+id,
    }) 

  }

  
  return (
    <>


<div className="card"  >
    {loading ? 
    <div>

    <LinearProgress style={{marginBottom:5}} />
    <div style={{padding:50,textAlign:'center'}}>Loading campaign ....... Please Wait !!!</div>
        
        
    </div> :
    <div style={{padding:20, }}>
    <CacheProvider value={muiCache}>
      <ThemeProvider theme={createTheme()}>
       
        <MUIDataTable
         // title={"Campaign Information"}   
          data={data}
          columns={columns}
          options={options}
         
         
        />
      </ThemeProvider>
    </CacheProvider>
    </div>
    }

    </div>



    </>
  );
}