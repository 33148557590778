import create from 'zustand'
import {devtools} from 'zustand/middleware'





const addPlatformRes = (set) => ({
    platform:[],
    setPlatformRes: (data) => set((state) => ({
        platform:data
    }))
})

const addPlatform = (set) => ({
    platform:'',
    setPlatform: (data) => set(() => ({
        platform:data
    }))
})


const addCampaignTypeRes = (set) => ({
    campaignType:[],
    setCampaignTypeRes: (data) => set((state) => ({
        campaignType:data
    }))
})



const addCampaignType = (set) => ({
    campaign:[],
    setCampaignType: (data) => set(() => ({
        campaign:data
    }))
})


const addGoalRes = (set) => ({
    goal:[],
    setGoalRes: (data) => set((state) => ({
        goal:data
    }))
})

const addGoal = (set) => ({
    goal:'',
    setGoal: (data) => set(() => ({
        goal:data
    }))
})


const optimizationKpiRes = (set) => ({
    Kpi:[],
    addOkpi: (data) => set((state) => ({
        Kpi:data
    }))
})

const addOptimizationKpi = (set) => ({
    Kpi:[],
    setOptimizationKpi: (data) => set(() => ({
        Kpi:data
    }))
})



const addFrontSecondaryKpi = (set) => ({
    Kpi:[],
    setSecondaryKpi: (data) => set(() => ({
        Kpi:data
    }))
})



const addContinuousTotalDays = (set) => ({
    days:[],
    setDays: (data) => set(() => ({
        days:data
    }))
})



const addContinuousSecondaryKpi = (set) => ({
    Kpi:[],
    setSecondaryKpi: (data) => set(() => ({
        Kpi:data
    }))
})


const addContinuousDailyBudget = (set) => ({
    budget:[],
    setBudget: (data) => set(() => ({
        budget:data
    }))
})


const addContinuousOptimizationKpi = (set) => ({
    Kpi:[],
    setSecondaryKpi: (data) => set(() => ({
        Kpi:data
    }))
})


const addEditCampaignDetails = (set) => ({
    campaignDetails:[],
    setCampaignDetails: (data) => set(() => ({
        campaignDetails:data
    }))
})





const addClientRes = (set) => ({
    client:[],
    setClientRes: (data) => set((state) => ({
        client:data
    }))
})

const addClient = (set) => ({
    client:[],
    setClient: (data) => set(() => ({
        client:data
    }))
})

const addGoogleCampaignRes = (set) => ({
    google_campaign:[],
    setGoogleCampaign: (data) => set((state) => ({
        google_campaign:data
    }))
})

const addGoogleCampaign = (set) => ({
    google_campaign:[],
    setGoogleCampaign: (data) => set(() => ({
        google_campaign:data
    }))
})

const addMasterCampaignRes = (set) => ({
    masterCampaign:[],
    setMasterCampaign: (data) => set((state) => ({
        masterCampaign:data
    }))
})

const addMasterCampaign = (set) => ({
    masterCampaign:'',
    setMasterCampaign: (data) => set((state) => ({
        masterCampaign:data
    }))
})



const addSubCampaign = (set) => ({
    subCampaign:'',
    setSubCampaign: (data) => set((state) => ({
        subCampaign:data
    }))
})


const addDuplicateValidation = (set) => ({
    duplicate:'',
    setDuplicate: (data) => set((state) => ({
        duplicate:data
    }))
})


const addAccount = (set) => ({
    account:'',
    setAccount: (data) => set((state) => ({
        account:data
    }))
})

const addSecondaryAccount = (set) => ({
    saccount:'',
    setSecondary: (data) => set((state) => ({
        saccount:data
    }))
})



const addPeriodsTotalBudget = (set) => ({
    pbudget:[],
    setPeriodTotalBudget: (data) => set((state) => ({
        pbudget:data
    }))
})



const addEstimateTotalGoal = (set) => ({
    tgoal:[],
    setEstimatedTotalGoal: (data) => set((state) => ({
        tgoal:data
    }))
})



const addPickDates = (set) => ({
    dates:'continuous',
    setPickDates: (data) => set((state) => ({
        dates:data
    }))
})



const addConversion = (set) => ({
    conversion:'',
    setConversion: (data) => set((state) => ({
        conversion:data
    }))
})


const addNotes = (set) => ({
    notes:'',
    setNotes: (data) => set((state) => ({
        notes:data
    }))
})



const addCampaignStartDate = (set) => ({
    date:'',
    setCampaignStartDate: (data) => set((state) => ({
        date:data
    }))
})


const addCampaignEndDate = (set) => ({
    date:'',
    setCampaignEndDate: (data) => set((state) => ({
        date:data
    }))
})


const addGoogleBudget = (set) => ({
    budget:[],
    setGoogleBudget: (data) => set((state) => ({
        budget:data
    }))
})

const addCampaignDates = (set) => ({
    dates:[],
    setDates: (data) => set((state) => ({
        dates:data
    }))
})


const addDaysOff = (set) => ({
    daysOff:[],
    setDaysOff: (data) => set(() => ({
        daysOff:data
    }))
})


const addPeriods = (set) => ({
    periods:[],
    setPeriods: (data) => set((state) => ({  
        periods:data

    }))
    
})

const addPeriodsDaysOff = (set) => ({
    periodsDaysOff:[],
    setPeriodsDaysOff: (data) => set((state) => ({  
        periodsDaysOff:data

    }))
    
})

const addPeriodsDaysOffInfo = (set) => ({
    periodsDaysOffInfo:[],
    setPeriodsDaysOffInfo: (data) => set((state) => ({  
        periodsDaysOffInfo:data

    }))
    
})



const addPeriodsInfo = (set) => ({
    periodsInfo:[],
    setPeriodsInfo: (data) => set((state) => ({  
        periodsInfo:data

    }))
    
})

const addPeriodsDuration = (set) => ({
    periodsDuration:[],
    setPeriodsDuration: (data) => set((state) => ({  
        periodsDuration:data

    }))
    
})

const addTotalBudget = (set) => ({
    totalBudget:[],
    setTotalBudget: (data) => set((state) => ({  
        totalBudget:data

    }))
    
})


const addPeriodBudget = (set) => ({
    periodBudget:[],
    setPeriodBudget: (data) => set((state) => ({  
        periodBudget:data

    }))
    
})



const addPeriodValidate = (set) => ({
    validate:[true],
    setValidate: (data) => set((state) => ({  
        validate:data

    }))
    
})




const addCpaValidate = (set) => ({
    validate:[true],
    setValidate: (data) => set((state) => ({  
        validate:data

    }))
    
})


const addCpcValidate = (set) => ({
    validate:[true],
    setValidate: (data) => set((state) => ({  
        validate:data

    }))
    
})


const addTotalEstimatedGoal = (set) => ({
    estimatedGoal:[],
    setEstimatedGoal: (data) => set((state) => ({  
        estimatedGoal:data

    }))
    
})

const addTotalEstimatedCostPerConversion = (set) => ({
    estimatedCostPerConversion:[],
    setEstimatedCostPerConversion: (data) => set((state) => ({  
        estimatedCostPerConversion:data

    }))
    
})



const addEstimatedGoal = (set) => ({
    estimatedGoal:[],
    setEstimatedGoal: (data) => set((state) => ({  
        estimatedGoal:data

    }))
    
})

const addEstimatedPeriodsGoal = (set) => ({
    estimatedPeriodsGoal:[],
    setEstimatedPeriodsGoal: (data) => set((state) => ({  
        estimatedPeriodsGoal:data

    }))
    
})

const addEstimatedCostPerConversion = (set) => ({
    estimatedCostPerConversion:[],
    setEstimatedCostPerConversion: (data) => set((state) => ({  
        estimatedCostPerConversion:data

    }))
    
})

const addEstimatedSecondaryKpi = (set) => ({
    skpi:[],
    setSecondaryKpi: (data) => set((state) => ({  
        skpi:data

    }))
    
})



const addWeekendsOff = (set) => ({
    weekends:['no'],
    setWeekendsOff: (data) => set((state) => ({  
        weekends:data

    }))
    
})



const addCampaignOwner = (set) => ({
    campaignOwner:[],
    setCampaignOwner: (data) => set((state) => ({  
        campaignOwner:data

    }))
    
})

const addPerformanceManager = (set) => ({
    performanceManager:[],
    setPerformanceManager: (data) => set((state) => ({  
        performanceManager:data

    }))
    
})

const addCampaignBackup = (set) => ({
    campaignBackup:[],
    setCampaignBackup: (data) => set((state) => ({  
        campaignBackup:data

    }))
    
})



const addTeamLeader = (set) => ({
    teamLeader:[],
    setTeamLeader: (data) => set((state) => ({  
        teamLeader:data

    }))
    
})


export const usePlatformRes = create(addPlatformRes)
export const usePlatform = create(addPlatform)

export const useCampaignTypeRes = create(addCampaignTypeRes)
export const useCampaignType = create(addCampaignType)

export const useGoalRes = create(addGoalRes)
export const useGoal = create(addGoal)

export const useClientRes = create(addClientRes)
export const useClient = create(addClient)

export const useGoogleCampaignRes = create(addGoogleCampaignRes)
export const useGoogleCampaign = create(addGoogleCampaign)

export const useOptimizationKpiRes = create(optimizationKpiRes)
export const useOptimizationKpi = create(addOptimizationKpi)

export const useFrontSecondaryApi = create(addFrontSecondaryKpi)

export const useMasterCampaign = create(addMasterCampaign)

export const useMasterCampaignRes = create(addMasterCampaignRes)

export const useSubCampaign = create(addSubCampaign)

export const useDuplicateValidation = create(addDuplicateValidation)

export const useAccount = create(addAccount)

export const useSecondaryAccount = create(addSecondaryAccount)

export const usePickDates = create(addPickDates)

export const useConversion = create(addConversion)

export const useNotes = create(addNotes)

export const useDaysOff = create(addDaysOff)

export const usePeriodsDaysOff = create(addPeriodsDaysOff)

export const usePeriodsDaysOffInfo = create(addPeriodsDaysOffInfo)

export const usePeriods = create(addPeriods)

export const usePeriodsInfo = create(addPeriodsInfo)

export const usePeriodBudget = create(addPeriodBudget)

export const usePeriodTotalBudget = create(addPeriodsTotalBudget)

export const useEstimatedTotalGoal = create(addEstimateTotalGoal)

export const usePeriodValidate = create(addPeriodValidate)
export const useCpaValidate = create(addCpaValidate)
export const useCpcValidate = create(addCpcValidate)

export const usePeriodsDuration = create(addPeriodsDuration)

export const useTotalBudget = create(addTotalBudget)

export const useGoogleBudget = create(addGoogleBudget)

export const useWeekendsOff = create(addWeekendsOff)

export const useCampaignStartDate = create(addCampaignStartDate)
export const useCampaignEndDate = create(addCampaignEndDate)

export const useTotalEstimatedGoal = create(addTotalEstimatedGoal)
export const useTotalEstimatedCostPerConversion = create(addTotalEstimatedCostPerConversion)

export const useEstimatedGoal = create(addEstimatedGoal)
export const useEstimatedCostPerConversion = create(addEstimatedCostPerConversion)

export const useEstimatedPeriodsGoal = create(addEstimatedPeriodsGoal)

export const useEstimatedSecondaryKpi = create(addEstimatedSecondaryKpi)
export const useContinousSecondaryKpi = create(addContinuousSecondaryKpi)

export const useContinuousDailyBudget = create(addContinuousDailyBudget);
export const useContinuousOptimizationKpi = create(addContinuousOptimizationKpi);

export const useContinousTotalDays = create(addContinuousTotalDays);
export const useCampaignOwner = create(addCampaignOwner);

export const usePerformanceManager = create(addPerformanceManager)
export const useCampaignBackup = create(addCampaignBackup)
export const useTeamLeader = create(addTeamLeader)

export const useCampaignDates = create(addCampaignDates)

export const useEditCampaignDetails = create(addEditCampaignDetails)



